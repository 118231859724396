import axios from 'axios';
import { Ref, ref } from 'vue';
import JsonAction from '@/enums/JsonAction';
import { translate } from '@/js/frontend/filters';

export default function useProcessStep(stepId: string, processId: number) {
  const stepFormErrors: Ref<string[]> = ref([]);

  /**
   * StepId of current Step
   */
  function getStepId() {
    return stepId;
  }

  /**
   * Retrieving current StepStatus to display errors in step
   */
  async function getStepStatus() {
    try {
      const response = await axios.get(
        `/de/process/${processId}/step/${stepId}/json/${JsonAction.GET_STEP_STATUS}`
      );
      stepFormErrors.value = response.data.stepStatus.errors;
    } catch {
      stepFormErrors.value = [translate('messages', 'json.get_step_status_failed')];
    }
    document.querySelector('main')?.scrollIntoView({ behavior: 'smooth' });
  }

  const isSaving = ref(false);

  /**
   * Sending the payload to backend to save stepData
   * @param payload
   */
  async function saveForm(payload: unknown) {
    try {
      isSaving.value = true;
      const response = await axios.post(
        `/de/process/${processId}/step/${stepId}/json/${JsonAction.SAVE_DATA}`,
        payload
      );
      if (response.data.status === 'success') {
        window.location = response.data.url;
      } else {
        stepFormErrors.value = response.data.errors;
        (document.querySelector('main') as HTMLElement).scrollIntoView({
          behavior: 'smooth',
        });
      }
    } catch {
      stepFormErrors.value = [translate('messages', 'json.save_failed')];
      document.querySelector('main')?.scrollIntoView({ behavior: 'smooth' });
    } finally {
      isSaving.value = false;
    }
  }

  return {
    getStepId,
    stepFormErrors,
    getStepStatus,
    saveForm,
    isSaving,
  };
}
